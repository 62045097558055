import React from 'react';
import { createRoot } from 'react-dom/client';
import 'bulma/css/bulma.min.css';
import './index.css';
import './components/style.css';
import 'react-tooltip/dist/react-tooltip.css'
import App from './App';
import { Amplify } from 'aws-amplify';
import config from "./config.js";
import * as serviceWorker from './serviceWorker';

const amplifyConfig = {
  Auth: {
    Cognito: {
      mandatorySignIn: true,
      region: config.aws_amplify.Auth.Region,
      userPoolId: config.aws_amplify.Auth.UserPoolId,
      userPoolClientId: config.aws_amplify.Auth.UserPoolWebClientId,
      identityPoolId: config.aws_amplify.Auth.IdentityPoolId,
    }
  }
};

try {
  Amplify.configure(amplifyConfig);
} catch (error) {
  console.error("Error configuring Amplify:", error);
}

const root = createRoot(document.getElementById('root'));
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();