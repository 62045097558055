import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Products from "./components/Products";
import ProductAdmin from "./components/ProductAdmin";
import RequireAuth from "./components/RequireAuth";
import config from "./config.js";
import Error from "./components/error";
import Authentication from "./Authentication";
import axios from "axios";
import Footer from "./components/Footer";

class App extends Component {
  state = {
    isAuthenticated: false,
    isAuthenticating: true,
    user: null,
    isAdmin: false,
    userLicenses: [],
    error: null,
  };

  async componentDidMount() {
    await this.checkAuthStatus();
  }

  setAuthStatus = (authenticated) => {
    this.setState({ isAuthenticated: authenticated });
  };

  setUser = (user) => {
    this.setState({ user: user });
  };

  setUserLicenses = (licenses) => {
    this.setState({ userLicenses: licenses });
  };
  setError = (error) => {
    this.setState({ error });
  };

  clearAuthState = () => {
    this.setState({
      isAuthenticated: false,
      user: null,
      isAdmin: false,
      userLicenses: [],
      error: null
    });
  };

  checkAuthStatus = async () => {
    const auth = new Authentication();
    try {
      const idTokenPayload = await auth.login();
      if (idTokenPayload) {
        this.setAuthStatus(true);
        this.setUser(idTokenPayload);

        const session = sessionStorage.getItem("idToken");
        if (session) {
          await Promise.all([
            this.fetchAdminStatus(idTokenPayload.email, session),
            this.fetchUserLicenses(idTokenPayload.email, session)
          ]);
        }
      } else {
        this.clearAuthState();
      }
    } catch (error) {
      console.error('Error:', error);
      this.setError('Error checking user session');
      this.clearAuthState();
      auth.clearAllStorage();
    } finally {
      this.setState({ isAuthenticating: false });
    }
  };

  fetchAdminStatus = async (email, session) => {
    try {
      const response = await axios.get(
        `${config.api.invokeUrl}/GetAdminInfo?email=${encodeURIComponent(email)}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${session}`,
          },
        }
      );

      if (response.status === 200) {
        this.setState({ isAdmin: response.data.isAdmin });
      }
    } catch (error) {
      console.error('Error fetching admin status:', error);
      this.setError('Error fetching admin status');
    }
  };

  fetchUserLicenses = async (email, session) => {
    try {
      const response = await axios.get(
        `${config.mumapapi.invokeUrl}/UserLicenses?email=${encodeURIComponent(email)}`,
        {
          headers: {
            Authorization: `Bearer ${session}`,
          },
        }
      );

      if (response.status === 200) {
        const { licenses } = response.data;
        this.setState({ userLicenses: licenses });
      }
    } catch (error) {
      console.error('Error fetching user licenses:', error);
      this.setError('Error fetching user licenses');
    }
  };

  handleLogin = async () => {
    try {
      const auth = new Authentication();
      auth.clearAllStorage();
      window.location.href = auth.loginUrl;
    } catch (error) {
      console.error('Login error:', error);
      this.clearAuthState();
    }
  };

  handleLogout = async () => {
    try {
      const auth = new Authentication();
      this.clearAuthState();
      await auth.logout();
    } catch (error) {
      console.error('Logout error:', error);
      this.clearAuthState();
      const auth = new Authentication();
      auth.clearAllStorage();
      window.location.reload();
    }
  };
  
  render() {
    const authProps = {
      isAuthenticated: this.state.isAuthenticated,
      user: this.state.user,
      setAuthStatus: this.setAuthStatus,
      setUser: this.setUser,
      isAdmin: this.state.isAdmin,
      userLicenses: this.state.userLicenses,
    };
    return (
      !this.state.isAuthenticating && (
        <div className="App">
          <Router>
            <RequireAuth setUserLicenses={this.setUserLicenses}>
              <div>
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={(props) => (
                      <Products {...props} auth={authProps} handleLogin={this.handleLogin} handleLogout={this.handleLogout}/>
                    )}
                  />
                  <Route
                    exact
                    path="/admin"
                    render={(props) => {
                      if (authProps.isAuthenticated && authProps.isAdmin) {
                        return <ProductAdmin {...props} auth={authProps} />;
                      } else {
                        return <Redirect to="/" />;
                      }
                    }}
                  />
                  <Route
                    path="/*"
                    render={(props) => <Error {...props} auth={authProps} />}
                  />        
                </Switch>
                <Footer />
              </div>
            </RequireAuth>
          </Router>
        </div>
      )
    );
  }
}

export default App;